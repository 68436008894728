import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { Avatar, Box, Button, Card, CardMedia, ClickAwayListener, Chip, Link, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useTheme } from "@mui/material/styles";
import { ReactionBarSelector } from '@charkour/react-reactions';
import { collection, onSnapshot, serverTimestamp, updateDoc, doc } from "firebase/firestore";
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'src/hooks/use-auth';

import { analytics, db } from 'src/libs/firebase';
import { logAnalyticsEvent } from 'src/utils/logging';
import FailSafeAvatar from 'src/pages/components/fail-safe-avatar';

export const ChatMessage = (props) => {
    const { id, ambassadorName, authorName, body, contentType, createdAt, isEdited, isUser, avatar, position, onEdit, onReply, replyMessageSender, replyMessageContent, activeThreadID, reactions, ...other } = props;

    const { user } = useAuth();
    const theme = useTheme();
    const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));

    const now = Date.now();
    const ago = (createdAt && (createdAt < now - 1000 * 60)) ? `${formatDistanceToNowStrict(createdAt)} ago` : "Just now";

    const ellipsisThreshold = matchUpSm ? 120 : 60;

    const [reactionSelectionOpen, setReactionSelectionOpen] = useState(false);

    const messageQuery = doc(db, `threads/${activeThreadID}/messages`, id);

    const showAvatar = !isUser && !!avatar && position === 'left';

    const likeRef = useRef(null);
    const loveRef = useRef(null);
    const laughRef = useRef(null);
    const surpriseRef = useRef(null);
    const thinkingRef = useRef(null);
    const pensiveRef = useRef(null);

    useEffect(() => {
        if (!!reactions && reactions.hasOwnProperty(user.id)) {
            const reactionRefs = {
                "👍": likeRef,
                "❤️": loveRef,
                "😆": laughRef,
                "😮": surpriseRef,
                "🤔": thinkingRef,
                "😔": pensiveRef
            };

            for (const [reaction, ref] of Object.entries(reactionRefs)) {
                let parentEl = ref.current?.parentElement?.parentElement;
                if (parentEl) {
                    if (reactions[user.id] === reaction) {
                        parentEl.style.backgroundColor = "lightcyan";
                        parentEl.style.borderRadius = "33%";
                    } else {
                        parentEl.style.backgroundColor = "transparent";
                    }
                }
            }
        }
    }, [reactions, likeRef.current, loveRef.current, laughRef.current, surpriseRef.current, thinkingRef.current, pensiveRef.current]);

    return (
        <Box
            id={id}
            sx={{
                display: 'flex',
                alignItems: position === 'right' ? 'flex-end' : 'flex-start'
            }}
            {...other}
            mt="8px !important"
        >
            <Stack
                alignItems="flex-start"
                direction={position === 'right' ? 'row-reverse' : 'row'}
                spacing={0}
                sx={{
                    maxWidth: 500,
                    ml: position === 'right' ? 'auto' : 0,
                    mr: position === 'left' ? 'auto' : 0,
                    columnGap: "7px",
                }}
            >
                <Box sx={{ flexGrow: 1, alignSelf: "flex-end" }}>
                    <Card
                        sx={{
                            backgroundColor: position === 'right' ? 'primary.main' : 'background.paper',
                            color: position === 'right' ? 'primary.contrastText' : 'text.primary',
                            px: 2,
                            py: 1,
                            textAlign: 'left',
                        }}
                    >
                        {!!replyMessageContent && !!replyMessageSender && (
                            <Stack
                                borderLeft={"3px solid gray"}
                                paddingLeft={1}
                                mb={2}
                            >
                                <Typography variant='body2' color="lightgray">
                                    {replyMessageSender}
                                </Typography>
                                <Typography variant='body2' color="lightgray">
                                    {replyMessageContent.length > ellipsisThreshold ? `${replyMessageContent.substring(0, ellipsisThreshold)}...` : replyMessageContent}
                                </Typography>
                            </Stack>
                        )}
                        <Box sx={{ mb: 1, display: "flex", flexDirection: "row" }}>
                            {
                                showAvatar && (
                                    <FailSafeAvatar
                                        profilePicURL={avatar}
                                        sz={25}
                                        sx={{ marginRight: 1 }}
                                    />
                                )
                            }
                            <Typography
                                color="inherit"
                                sx={{ textAlign: "start !important", display: "block" }}
                                variant="subtitle2"
                            >
                                {authorName}
                            </Typography>
                        </Box>
                        {contentType === 'image' && (
                            <CardMedia
                                onClick={() => { }}
                                image={body}
                                sx={{
                                    height: 200,
                                    width: 200
                                }}
                            />
                        )}
                        {contentType === 'text' && (
                            <Typography
                                color="inherit"
                                variant="body1"
                            >
                                {body}
                            </Typography>
                        )}
                    </Card>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
                            mt: 0,
                            px: 2,
                            columnGap: "7px",
                        }}
                    >
                        <Typography
                            color="text.secondary"
                            noWrap
                            variant="caption"
                            display={createdAt ? 'block' : 'none'}
                        >
                            {ago}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            {isEdited ? '(Edited)' : ''}
                        </Typography>
                    </Box>
                    <Stack mr={position === "right" ? "5px" : "0px"} ml={position === "right" ? "0px" : "5px"} columnGap="5px" direction="row" justifyContent={position === "right" ? "flex-end" : "flex-start"}>
                        {reactions && Object.entries(reactions).map(([userID, reaction]) => (
                            <Tooltip key={userID} title={userID === user.id ? "Me" : ambassadorName}>
                                <Chip
                                    sx={{
                                        display: !!reaction ? "inline-flex" : "none",
                                        p: 0,
                                        height: "26px",
                                        background: "transparent",
                                        borderStyle: "solid",
                                        borderWidth: userID === user.id ? "2px" : "1px",
                                        borderColor: userID === user.id ? "lightcyan" : "lightgray",
                                        '& .MuiChip-label': {
                                            fontSize: "1.2em",
                                            lineHeight: "1em",
                                        },
                                    }}
                                    label={reaction}
                                />
                            </Tooltip>
                        ))}
                    </Stack>
                </Box>
                <Stack
                    // pt={0.5}
                    // rowGap={0.5}
                    sx={{
                        alignItems: position === "right" ? "flex-end" : "flex-start",
                        opacity: (!!id && id !== '5e867f0a5bc0ff2bfa07bfa6') ? 1 : 0
                    }}
                >
                    <Button
                        sx={{
                            p: 0.25,
                            color: "lightgray",
                            '&:hover': {
                                color: (theme) => theme.palette.primary.main
                            },
                            minWidth: "auto",
                            justifyContent: position === "right" ? "flex-end" : "flex-start",
                        }}
                        disabled={!id || id === '5e867f0a5bc0ff2bfa07bfa6'}
                        onClick={() => onReply(id)}
                    >
                        <ReplyOutlinedIcon sx={{ transform: `scaleX(${position === 'right' ? 1 : -1})` }} />
                    </Button>
                    <Stack direction={position === "right" ? "row-reverse" : "row"}>
                        <Button
                            sx={{
                                p: 0.25,
                                color: "lightgray",
                                '&:hover': {
                                    color: (theme) => theme.palette.primary.main
                                },
                                minWidth: "auto",
                                justifyContent: position === "right" ? "flex-end" : "flex-start",
                            }}
                            disabled={!id || id === '5e867f0a5bc0ff2bfa07bfa6'}
                            onClick={(e) => {
                                if (position === "right") {
                                    onEdit(id);
                                } else {
                                    setReactionSelectionOpen(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                        >
                            {position === "right" ? <ModeEditIcon /> : <AddReactionOutlinedIcon />}
                        </Button>
                    </Stack>
                    <ClickAwayListener onClickAway={(e) => {
                        if (reactionSelectionOpen) {
                            setReactionSelectionOpen(false)
                        }
                    }}>
                        <ReactionBarSelector
                            reactions={[
                                { label: "Like", node: <div ref={likeRef}>👍</div>, key: "satisfaction" },
                                { label: "Love", node: <div ref={loveRef}>❤️</div>, key: "love" },
                                { label: "Haha", node: <div ref={laughRef}>😆</div>, key: "laughing" },
                                { label: "Wow", node: <div ref={surpriseRef}>😮</div>, key: "surprise" },
                                { label: "Hmm", node: <div ref={thinkingRef}>🤔</div>, key: "thinking" },
                                { label: "Sad", node: <div ref={pensiveRef}>😔</div>, key: "pensive" },
                            ]}
                            style={{
                                display: reactionSelectionOpen ? "flex" : "none",
                                position: "absolute",
                                columnGap: "0em",
                                paddingLeft: "1em",
                                paddingRight: "1em",
                                // marginRight: position === "right" ? "0.5em" : 0,
                                // marginLeft: position === "left" ? "0.5em" : 0,
                                marginTop: "1.5em",
                                zIndex: 100,
                                flexDirection: matchUpSm ? "row" : "column",
                            }}
                            iconSize="20px"
                            onSelect={async (reaction) => {
                                const reactionToEmoji = {
                                    "satisfaction": "👍",
                                    "love": "❤️",
                                    "laughing": "😆",
                                    "surprise": "😮",
                                    "thinking": "🤔",
                                    "pensive": "😔",
                                };

                                let newReactions = reactions ? reactions : {};
                                if (newReactions[user.id] === reactionToEmoji[reaction]) {
                                    newReactions[user.id] = null;
                                } else {
                                    newReactions[user.id] = reactionToEmoji[reaction];
                                }

                                logAnalyticsEvent("reacting_to_message", {
                                    messageID: id,
                                    userID: user.id,
                                    reaction: reaction,
                                });

                                // Edit Firestore message document
                                try {
                                    await updateDoc(messageQuery, {
                                        reactions: newReactions,
                                    });
                                } catch (err) {
                                    logAnalyticsEvent("error_reacting_to_message", {
                                        error: err,
                                        messageID: id,
                                        userID: user.id,
                                        reaction: reaction,
                                    });
                                }
                                setReactionSelectionOpen(false);
                            }}
                        />

                    </ClickAwayListener>
                </Stack>
            </Stack>
        </Box >
    );
};

ChatMessage.propTypes = {
    id: PropTypes.string,
    authorName: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    createdAt: PropTypes.number,
    position: PropTypes.oneOf(['left', 'right']),
    onReply: PropTypes.func,
    onEdit: PropTypes.func,
    replyMessageSender: PropTypes.string,
    replyMessageContent: PropTypes.string,
    activeThreadID: PropTypes.string,
    reactions: PropTypes.object,
    ambassadorName: PropTypes.string,
    isEdited: PropTypes.bool,
    isUser: PropTypes.bool,
    avatar: PropTypes.string,
};
