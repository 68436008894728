import PropTypes from 'prop-types';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { Seo } from './seo';
import { cloneElement, Component } from 'react';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        if (process.env.NODE_ENV !== 'development') {
            return { hasError: true };
        }

        console.log("IS DEVERROR", error);

        // throw error;
    }

    componentDidCatch(error, info) {
        // You can log the error to an error reporting service
        console.error(error, info);

        this.props.onError(error, info, this.props.itemID);
    }

    render() {
        if (this.state.hasError) {
            return !this.props.isAmbassadorError ? (
                <>
                    <Typography>
                        Something went wrong. Please try refreshing the page.
                    </Typography>
                    {/* <SplashScreen /> */}
                </>
            ) : null;
        }

        // Add a key that depends on the hasError state
        return cloneElement(this.props.children, { key: this.state.hasError });
    }
}

export const Error = (props) => {
    const { statusCode, title } = props;
    const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const text = `${statusCode}: ${title}`;

    return (
        <>
            <Seo title={text} />
            <Box
                component="main"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    py: '80px'
                }}
            >
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 6
                        }}
                    >
                        <Typography
                            align="center"
                            variant={mdUp ? 'h1' : 'h4'}
                        >
                            {text}
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

Error.propTypes = {
    statusCode: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
};
